

/*
.pagination {
  text-align: center;
}

.page-numbers {
  display: inline-block;
}
*/
