@charset "utf-8";

@import 'base/setting-houei';
@import 'dots/dots';
@import 'plain/plugin/ie9/plain-ie9';

.more-entry,
.event > .container,
.event__entry,
.header > .container,
.hero__content,
.works__list,
.lineup__list,
.blog-entry,
.topic-entry,
.banner__list,
.archive__item {
  display: table;
  table-layout: fixed;
}

.banner__list,
.land__item-inner,
.voice__item-inner {
  @include _mq(sm){
    display: table;
    table-layout: fixed;
  }
}

.intro__content,
.post-area,
.showroom,
.content__wrap {
  @include _mq(md){
    display: table;
    table-layout: fixed;
  }
}

.contact {
  @include _mq(md,down){
    display: table;
    table-layout: fixed;
  }
  @include _mq(sm,down){
    display: block;
  }
}

.event__entry-date,
.event__entry-ttl,
.header__branding,
.header__gnav,
.hero__heading,
.blog-entry__media,
.blog-entry__content,
.topics-entry__date,
.topics-entry__ttl,
.archive__media,
.archive__content {
  display: table-cell;
  vertical-align: top;
}

.showroom__item,
.land__media,
.land__content,
.voice__media,
.voice__content {
  @include _mq(sm){
    display: table-cell;
    vertical-align: top;
  }
}

.intro__heading,
.intro__txt,
.post-area__item,
.content__main,
.content__side {
  @include _mq(md){
    display: table-cell;
    vertical-align: top;
  }
}

.contact__item {
  @include _mq(md,down){
    display: table-cell;
    vertical-align: top;
  }
  @include _mq(sm,down){
    display: block;
  }
}


.more-entry__item,
.event__heading,
.event__list,
.event__date,
.event__ttl,
.works__list-item,
.lineup__list-item,
.banner__list-item,
.works-archive__item {
  display: inline-block;
  vertical-align: top;
}

.event { text-align: right; }
.showroom,
.hero__content,
.intro__content,
.post-area { width: 100%;}
.works__list,
.banner__list,
.works-archive { letter-spacing: -.4em; }
.contact { @include _mq(md,down){ width: 100%;} }
.burger-btn { margin: 12px 0 0 13px; }
.more-entry__item { width: auto; }
.event__heading:before { display: none; }
.event__entry-ttl { max-width: 280px; }
.header__branding { width: 320px; }
.showroom__item--header { vertical-align: middle; }
.footer__company,
.footer__info { text-align: center; }
.footer__info .menu { margin: auto !important; }
.footer__info .menu__item { padding: 0 _vr(.5) !important; }

.hero__media { display: none; }
.hero { background-image: url('../img/hero/hero-01.jpg'); }
.hero__heading { padding-top: _vr(10); color: #fff;}

.intro__heading {
  @include _mq(sm){
    padding-top: _vr(5.5);
    writing-mode: horizontal-tb;
  }
}

.works__list-item,
.banner__list-item,
.works-archive__item { letter-spacing: normal; }
.banner__list { width: 100%; }
.post-area__item { padding: _vr(2) _vr(1); }
.blog-entry__media { padding-right: _vr(.5); }
.topics-entry__date,
.topics-entry__ttl { padding: _vr(.5) _vr(.5) _vr(.5); }

.content__side { @include _mq(md){ width: 246px; } }
.archive__item { width: 100%; }
.entry { @include _mq(md){ width: 740px; } }
.land__media img,
.voice__media img { width: 264px; }
.land__content,
.voice__content { width:100%; max-width: 100%; }
.works-archive .card__content { padding: _vr(1); }
