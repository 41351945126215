.menu {
  display: table;
  width: 100%;
  table-layout: auto;

  &--left {
    width: auto;
    margin-left: 0;
    margin-right: auto;
  }

  &--center {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  &--right {
    width: auto;
    margin-left: auto;
    margin-right: 0;
  }

  &--column {
    display: block;
  }
}

.menu__item {
  display: table-cell;
  vertical-align: middle;
  word-break: nowrap;

  .menu--column > & {
    display: block;
  }
}

