@charset "UTF-8";

/// ブラウザ幅によるメディアクエリ記述を簡略化する
///
/// @param {string | number (with unit)} $breakpoint [md]
///   ブレイクポイント。`$dots-breakpoints-map` に設定したキーもしくは任意の数値を指定する。
///
/// @param {string} $range [up]
///   メディアクエリの適用範囲。`$breakpoint` で指定した幅より
///   大きい範囲に適用させる場合は `up`、小さい範囲に適用させる場合は `down` を指定する。
///   `down` を指定した場合、`max-width` には `$breakpoint` から 1px 引いた値がセットされる。
///
/// @require {variable} $dots-breakpoints-map
///
/// @example scss
///   .element {
///     @include _mq(sm) { font-size: 12px; }
///     @include _mq(sm, down) { font-size: 12px; }
///     @include _mq(500px) { font-size: 12px; }
///   }
///
///   // CSS Output
///   @media screen and (min-width: 576px) {
///     .element { font-size: 12px; }
///   }
///   @media screen and (max-width: 575px) {
///     .element { font-size: 12px; }
///   }
///  @media screen and (min-width: 500px) {
///    .element { font-size: 12px; }
///  }

@mixin _mq(
  $breakpoint: 'md',
  $range: 'up'
) {
  @if map-has-key($dots-breakpoints-map, $breakpoint) {
    $breakpoint: map-get($dots-breakpoints-map, $breakpoint);
    @if $range == down { $breakpoint: $breakpoint - 1px }
  }
  @if $range == 'up' {
    @media screen and (min-width: $breakpoint) { @content; }
  } @else if $range == 'down' {
    @media screen and (max-width: $breakpoint) { @content; }
  }
}
