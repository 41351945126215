.page-nav {
  display: table;
  width: 100%;
  table-layout: fixed;

  &__item {
    display: table-cell;
    width: 50%;
  }
}
